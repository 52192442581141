
import './Modal.scss';

const Modal = (props:any) => {
    return (
        <div className='bd-quiz-modal__main'>
         {props.children}
        </div>
    )
}
export default Modal;
