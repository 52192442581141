import "./ScoreItem.scss";
import { useEffect, useState, useContext } from "react";
import TableItem from "../../QuizScores/TableItem";
import BaseDS from "datastore/BaseDS";
import { Context } from "quiz-state-management/store";
import { TCategory } from "types";
import { subjectsIcons } from "quiz-state-management/constants";

interface Props {
  section: number;  
  categories: TCategory[];
}

const ScoreItem = (props: Props) => {
  const [scores, setScores] = useState<{ day: []; year: []; week: [] }>();
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    BaseDS.getScores(state.difficulty, props.section).then((data) => {
      setScores(data);
    });
  }, [props.section, state.difficulty, props.categories]);

  const createIcon = (id: number) => {
    const SubjectIcon = subjectsIcons[id];
    return <SubjectIcon />;
  };

  return (
    <div className={`bd-quiz-scoreitem__main`}>
      <div className={`bd-quiz-scoreitem__header`}>
        <div className={`bd-quiz-scoreitem__header-icon`}>
          {createIcon(props.section)}
        </div>
        <div className={`bd-quiz-scoreitem__header-text`}>
          {" "}
          {props.categories[props.section]
            ? props.categories[props.section].label
            : ""}
        </div>
      </div>
      <div className="bd-quiz-scoreitem__scores">
        {scores &&
          Array.from(Array(4)).map((_: any, index: number) => {
            return (
              <TableItem
                customClass="bd-quiz-scoreitem__scores_item"
                key={index}
                userId={
                  scores.week[index] && (scores.week[index]["userId"] || null)
                }
                data={{
                  column1: (scores.week[index] && scores.week[index]['id']) || index + 1,
                  column2: scores.week[index] ? scores.week[index]["name"] : '-',
                  column3: scores.week[index] ? scores.week[index]["score"] : '-',
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ScoreItem;
