import QuestionNavigator from "components/quiz-screens/Quiz/QuestionNavigator";
import "./QuestionContainer.scss";
import { Context } from "quiz-state-management/store";
import { useContext } from "react";
import {ReactComponent as AudioIcon} from 'assets/quiz/audio-icon.svg';
import TextDecoder from '../../../utils/TextDecoder';

const QuestionContainer = (props:any) => {
  const [state] = useContext(Context);

  const playMp3 = () => {
     props.playMp3();
  }

  return (
    <div className="bd-quiz-question-container__main">
     <AudioIcon onClick={playMp3} className="bd-quiz-question-container__icon"/>
      <div className="bd-quiz-question-container__question-text-container">
         <div className="bd-quiz-question-container__question-text">
        {state.data && TextDecoder.surroundEmojis(state.data[state.questionId].question, state.section)}
      </div>
      </div>   
      <div className="bd-quiz-question-container__navigator">
        <QuestionNavigator />
      </div>
    </div>
  );
};

export default QuestionContainer;
