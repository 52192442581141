import { Storage } from 'aws-amplify';

const responseMap: any = {};

export default class FileDS {

  static getSecuredUrlFile(key: string, callback: Function, size?: string | number) {
    Storage.get(key, {
      customPrefix: {
        public: ''
      },
    }).then((url: any) => {
      callback(url)
    });
  }

  static downloadFile(key: string, callback: Function, errorCallBack?: Function, size?: string | number) {
    if (responseMap[key]) {
      console.log('from cache => ' + key);
      callback(responseMap[key]);
    } else {
      console.log('Not from cache => ' + key);
      try {
      Storage.get(key, {
        customPrefix: {
          public: ''
        },
        download: true
      }).then((response: any) => {
        responseMap[key] = response;
        callback(response);
      }).catch(error => {
        errorCallBack && errorCallBack(error);
      });
    } catch(e){
      errorCallBack && errorCallBack(e);
    }
    }
  }

}

