import Overlay from "components/quiz-shared/Overlay";
import Modal from "components/quiz-shared/Modal";
import QuizButton from "components/quiz-shared/QuizButton";
import { ReactComponent as GoldCup } from "assets/quiz/gold-cup.svg";
import "./LostModal.scss";
interface Props {
  onReplay: Function;
  onGoHome: Function;
  onGoScores: Function;
}

const LostModal = (props: Props) => {
  return (
    <div className="bd-quiz-lost-modal__main">
      <Overlay>
        <Modal>
          <div className="bd-quiz-lost-modal__container">           
            <div className="bd-quiz-lost-modal__text">PERDU!</div>
            <div className="bd-quiz-lost-modal__buttons">
              <div className="bd-quiz-lost-modal__buttons-rejouer">
                <QuizButton
                  label="REJOUER"
                  theme="blue"
                  onClick={props.onReplay}
                />
              </div>
              <div className="bd-quiz-lost-modal__buttons-accueil">
                <QuizButton
                  label="ACCUEIL"
                  theme="blue"
                  onClick={props.onGoHome}
                />
              </div>
            </div>
            <div className="bd-quiz-lost-modal__button-scores__container">
              <GoldCup className="bd-quiz-lost-modal__button-scores__icon"/>
              <div className="bd-quiz-lost-modal__button-scores__button">
                <QuizButton
                  label="TABLEAU DES SCORES"
                  theme="brown"
                  onClick={props.onGoScores}
                />
              </div>
            </div>
          </div>
        </Modal>
      </Overlay>
    </div>
  );
};

export default LostModal;
