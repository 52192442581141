import "./CategorySelector.scss";
import React, { useState, useRef, useEffect, useContext } from "react";
import { ReactComponent as ArrowLeft } from "assets/quiz/arrow-left-v2.svg";
import { ReactComponent as ArrowRight } from "assets/quiz/arrow-right-v2.svg";
import clickSound from "assets/quiz/sounds/navigation.mp3";
import useSound from "use-sound";
import { TCategory } from "types";
import { ReactComponent as IconLock } from "assets/v3/icon-lock.svg";
import { BlockerContext } from "components/utils/BlockerContext";
import { UserContext } from "components/utils/UserContext";
import { isPublished } from "..";
import { captureRejections } from "events";

interface Props {
  defaultValue?: number;
  onSelect: Function;
  categories: TCategory[];
  subjectsIcons: any[];
}

const CategorySelector = (props: Props) => {
  const [categories, setCategories] = useState<TCategory[]>();
  const [playSound] = useSound(clickSound);
  const categoryWrapperRef = useRef<any>();
  const { setBlock, setItemId, setAllData } = useContext(BlockerContext);
  const { userHasAccess } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState<number>(
    props.defaultValue || 0
  );

  const calculateLoaderSize = (optimalSize: number) => {
    const screen = document.getElementsByClassName("ks-mainFrame");
    return screen && screen.length
      ? (optimalSize * screen[0].clientWidth) / 1527
      : optimalSize;
  };

  const scrollLeft = () => {
    playSound();
    categoryWrapperRef.current.scroll({
      left: categoryWrapperRef.current.scrollLeft + calculateLoaderSize(300),
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    playSound();
    categoryWrapperRef.current.scroll({
      left: categoryWrapperRef.current.scrollLeft - calculateLoaderSize(300),
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (categories) {
      document
        .getElementById("bd-quiz-category-" + props.defaultValue)
        ?.scrollIntoView({
          behavior: "smooth",
        });
    }
  }, [props.defaultValue, categories]);

  useEffect(() => {
    if (props.categories) {
      const sortedCat = props.categories;
      setCategories(sortedCat);
      setAllData(sortedCat);
    }
  }, [props.categories]);

  useEffect(() => {
    if (props.categories && props.categories[selectedIndex]) {
      const categoriesTmp = props.categories;
      const currentCat = categoriesTmp[selectedIndex];
      if (
        userHasAccess(currentCat.id, currentCat.freemium) !== undefined &&
        !userHasAccess(currentCat.id, currentCat.freemium) &&
        !currentCat.defaultSelected
      ) {
        const firstCatAccessible = categoriesTmp.findIndex((cat) =>
          userHasAccess(cat.id, cat.freemium)
        );
        if (firstCatAccessible > -1) {
          props.onSelect(firstCatAccessible);
          setSelectedIndex(firstCatAccessible);
        } else {
          const firstDefaultCat = categoriesTmp.length - 1;
          props.onSelect(firstDefaultCat > -1 ? firstDefaultCat : 0);
          setSelectedIndex(firstDefaultCat > -1 ? firstDefaultCat : 0);
        }
      }
    }
  }, [props.categories, selectedIndex, userHasAccess]);

  const createIcon = (id:number) => {
    const SubjectIcon = props.subjectsIcons[id]
    return <SubjectIcon/>
  }

  return (
    <div className={`bd-quiz-categoryselector`}>
      <ArrowLeft
        onClick={scrollRight}
        className={`bd-quiz-categoryselector__arrow`}
      />
      <div
        ref={categoryWrapperRef}
        className={`bd-quiz-categoryselector__container`}
      >
        {categories &&
          categories.map((cat, index) => (          
            <div
              id={`bd-quiz-category-${index}`}
              className={`bd-quiz-categoryselector__category ${
                !userHasAccess(cat.id, cat.freemium) && "blocked"
              } ${selectedIndex === index ? " selected " : ""} ${
                !isPublished(cat.publicationDate) ? "disabled" : ""
              }`}
              key={index}
              onClick={(event: any) => {
                playSound();
                if (!userHasAccess(cat.id, cat.freemium) && !cat.freemium) {
                  event.preventDefault();
                  setItemId(cat.id);
                  setBlock(true);
                  return false;
                } else if (isPublished(cat.publicationDate)) {
                  setSelectedIndex(index);
                  props.onSelect(index);
                }
              }}
            >
              <div className="bd-quiz-categoryselector__category-subject-icon">
               {createIcon(index)}
              </div>
              <div className="bd-quiz-categoryselector__category-label">{cat.label && cat.label !== "" ? cat.label : cat.name}</div>
              <div className="overlay">
                <IconLock />
              </div>
            </div>
          ))}
      </div>
      <ArrowRight
        onClick={scrollLeft}
        className={`bd-quiz-categoryselector__arrow`}
      />
    </div>
  );
};

export default CategorySelector;
