import HomeLeftAreaV3 from 'components/elements/common/freemium/HomeLeftArea';
import { UserContext } from 'components/utils/UserContext';
import { useContext } from 'react';
import QuizLeftAreaV2 from './HomeLeftArea';
import LayoutV2 from './LayoutV2';

function MainLayout(props: { children: any, className: string }) {
	const { freemiumEnabled } = useContext(UserContext);
  
  return (
    <LayoutV2 className={props.className} leftArea={freemiumEnabled ? <HomeLeftAreaV3 /> : <QuizLeftAreaV2 />}>
      {props.children}
    </LayoutV2>
  );
}

export default MainLayout;
