import Overlay from 'components/quiz-shared/Overlay';
import './GoScreen.scss';
import QuizButton from "components/quiz-shared/QuizButton";
import {ReactComponent as CliquezIci} from 'assets/quiz/cliquez-ici.svg';
import { Context } from "quiz-state-management/store";
import { useContext } from "react";

interface Props { 
   onGo:Function
  }

const GoScreen = (props:Props) => {
    const [state] = useContext(Context);
    
    const onGo = () => {
        if(state.mp3Loaded){
             props.onGo();
        }     
    }
    return (
        <div className='bd-quiz-goScreen__main'>
            <Overlay>  
            {state.mp3Loaded && <CliquezIci className="bd-quiz-goScreen__info"/>} 
            {!state.mp3Loaded && <div className="bd-quiz-goScreen__loading">Loading...</div>}   
            <div className='bd-quiz-goScreen__go'>
            {state.mp3Loaded && <QuizButton shadow="true" font="large" theme="blue" onClick={onGo} label="Go!" />}
                </div>    
            </Overlay>
        </div>
    )
}
export default GoScreen;