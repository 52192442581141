import "./HomeLeftArea.scss";
import { ReactComponent as QuizIcon } from "assets/v2/qz-home-left-logo.svg";
import HooPowAppMenuV3 from "components/elements/common/internalrouter/HooPowAppMenuV3";

const HomeLeftAreaV3 = () => {

	return (
		<>
			<div className="ms-home-icon-v3"> <QuizIcon /> </div>
			<HooPowAppMenuV3 />
		</>
	);
};

export default HomeLeftAreaV3;
