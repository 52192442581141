import {ReactComponent as Sunset} from "assets/quiz/themes/theme-sunset-preview.svg";
import {ReactComponent as Day} from "assets/quiz/themes/theme-day-preview.svg";
import {ReactComponent as Night} from "assets/quiz/themes/theme-night-preview.svg";

import {ReactComponent as SunsetBackScroll} from "assets/quiz/themes/theme-sunset-back-scroll.svg";
import {ReactComponent as DayBackScroll} from "assets/quiz/themes/theme-day-back-scroll.svg";
import {ReactComponent as NightBackScroll} from "assets/quiz/themes/theme-night-back-scroll.svg";

import {ReactComponent as BoatPlayerMatelot } from 'assets/quiz/boats/boat-player-matelot.svg';
import {ReactComponent as BoatComputerMatelot} from 'assets/quiz/boats/boat-computer-matelot.svg';
import {ReactComponent as BoatPlayerPecheur} from 'assets/quiz/boats/boat-player-pecheur.svg';
import {ReactComponent as BoatComputerPecheur} from 'assets/quiz/boats/boat-computer-pecheur.svg';
import {ReactComponent as BoatPlayerSkipper} from 'assets/quiz/boats/boat-player-skipper.svg';
import {ReactComponent as BoatComputerSkipper} from 'assets/quiz/boats/boat-computer-skipper.svg';

import {ReactComponent as IconMatelot}  from 'assets/quiz/boats/icon-matelot.svg';
import {ReactComponent as IconPecheur} from 'assets/quiz/boats/icon-pecheur.svg';
import {ReactComponent as IconSkipper} from 'assets/quiz/boats/icon-skipper.svg';

import {ReactComponent as SubjectCoran}  from 'assets/quiz/subjects/icon-coran.svg';
import {ReactComponent as SubjectCultureGenerale}  from 'assets/quiz/subjects/icon-culture-generale.svg';
import {ReactComponent as SubjectPaysMusulmans}  from 'assets/quiz/subjects/icon-pays-musulmans.svg';
import {ReactComponent as SubjectProphetes}  from 'assets/quiz/subjects/icon-prophetes.svg';


export const Difficulty = [
  { text: "Mode Petit Matelot", duration: 120, boats:[BoatPlayerMatelot, BoatComputerMatelot], icon: IconMatelot },
  { text: "MODE MARIN PÊCHEUR  ", duration: 120, boats:[BoatPlayerPecheur, BoatComputerPecheur], icon: IconPecheur},
  { text: "MODE SKIPPER", duration: 120, boats:[BoatPlayerSkipper, BoatComputerSkipper], icon: IconSkipper}
];

export const initialScore = 12000;

export const Sections = {
  PROPHETES: { text: "Les Prophètes ﷺ" },
  SECTION2: { text: "Section 2" },
  SECTION3: { text: "Section 3" },
};

export const subjectsIcons = [ SubjectProphetes, SubjectCoran, SubjectPaysMusulmans, SubjectCultureGenerale ];

export const Themes = [
  {
    name: "day",
    preview: Day,
    backScroll: DayBackScroll,
  },
  {
    name: "sunset",
    preview: Sunset,
    backScroll: SunsetBackScroll,
  },  
  {
    name: "night",
    preview: Night,
    backScroll: NightBackScroll,
  }
];

export const Modes = {
  ENTRAINENEMNT: { text: "Entrainement" },
  COMPETITION: { text: "Competition" },
};
