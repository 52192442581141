import "./QuestionChoice.scss";
import { ReactComponent as LightBulbGrey } from "assets/quiz/light-bulb-grey.svg";
import { ReactComponent as LightBulbYellow } from "assets/quiz/light-bulb-yellow.svg";
import { ReactComponent as LightBulbRed } from "assets/quiz/light-bulb-red.svg";
import TextDecoder from '../../../utils/TextDecoder';
import { Context } from "quiz-state-management/store";
import { useContext } from "react";

interface Props {
  type: string;
  label: string;
  clicked: any;
  name: string;
  answer: string;
  onClick: Function;
}

const QuestionChoice = (props: Props) => {
  const [state] = useContext(Context);

  const handleOnCLick = () => {
    props.onClick(props.name);
  };
  const getClass = () => {
    if(props.clicked === '') {
      return;
    }
    if(props.clicked === props.name && props.name === props.answer){
      return 'correct'
    }
    if(props.clicked === props.name && props.name !== props.answer){
      return 'incorrect'
    }
    return 'faded';
  }
  
  return (
    <div
      className={`bd-quiz-question-choice__main bd-quiz-question-choice__main--${getClass()} ${
        props.type === "double"
          ? "bd-quiz-question-choice-double"
          : "bd-quiz-question-choice-multi"
      }`}
      onClick={handleOnCLick}
    >
      <div
        className={`bd-quiz-question-choice__label ${
          props.label.trim() === "+" || props.label.trim() === "-"
            ? "bd-quiz-question-choice__label--big"
            : ""
        }`}
      >
        {TextDecoder.surroundEmojis(props.label, state.section)}
      </div>
      <div className="bd-quiz-question-choice__icon-container">
        {props.clicked === "" && (
          <LightBulbGrey className="bd-quiz-question-choice__icon" />
        )}
        {(props.name === props.answer && props.clicked === props.name) && (
          <LightBulbYellow className="bd-quiz-question-choice__icon" />
        )}
        {(props.name !== props.answer && props.clicked === props.name) && (
          <LightBulbRed className="bd-quiz-question-choice__icon" />
        )}
      </div>
    </div>
  );
};

export default QuestionChoice;
