import { ReactComponent as ArrowLeft } from "assets/quiz/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/quiz/arrow-right.svg";
import { ReactComponent as PauseIcon } from "assets/quiz/pause-icon.svg";
import { Context } from "quiz-state-management/store";
import { useContext } from "react";
import clickSound from "assets/quiz/sounds/navigation.mp3";
import useSound from "use-sound";

import "./QuestionNavigator.scss";

const QuestionNavigator = (props: any) => {
  const [state, dispatch] = useContext(Context);
  const [playSound] = useSound(clickSound);

  const nextQuestion = () => {
    if (
      (state.data && state.questionId === state.data.length - 1) ||
      (state.correct_incorrect !== "" && state.mode === 0)
    ) {
      return;
    }
    if (state.bruitages) {
      playSound();
    }
    dispatch({ type: "SET_NEXT_QUESTION" });
    dispatch({ type: "ADD_TO_SCORE_SEQUENCE", payload: "skipped" });
  };
  const previousQuestion = () => {
    if (state.questionId === 0 || state.mode === 0) {
      return;
    }
    if (state.bruitages) {
      playSound();
    }
    dispatch({ type: "SET_PREVIOUS_QUESTION" });
  };

  const doPause = () => {
    dispatch({ type: "SET_PAUSE", payload: true });
  };

  return (
    <div className="bd-quiz-question-navigator__main">
      <ArrowLeft
        onClick={previousQuestion}
        className={`bd-quiz-question-navigator__arrow ${state.questionId === 0 || state.mode === 0
            ? "bd-quiz-question-navigator__arrow--inactive"
            : ""
          }`}
      />
      <PauseIcon
        className="bd-quiz-question-navigator__pause-icon"
        onClick={doPause}
      />
      <ArrowRight
        onClick={nextQuestion}
        className={`bd-quiz-question-navigator__arrow ${(state.data && state.questionId === state.data.length - 1) ||
            (state.correct_incorrect !== "" && state.mode === 0)
            ? "bd-quiz-question-navigator__arrow--inactive"
            : ""
          }`}
      />
    </div>
  );
};

export default QuestionNavigator;
