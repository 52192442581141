import "./QuestionChoices.scss";
import { Context } from "quiz-state-management/store";
import { useContext, useEffect } from "react";
import QuestionChoice from "./QuestionChoice";
import { getChoices } from "quiz-state-management/utils";
import React, { useState, useMemo, useLayoutEffect } from "react";

const QuestionChoices = (props: any) => {
  const [state, dispatch] = useContext(Context);
  const [clicked, setClicked] = useState("");
  
  const choicesData = useMemo(() => {
     return getChoices(state.data[state.questionId]);
  },[state.questionId]);

  useLayoutEffect(() => {
    setClicked("");
  }, [state.questionId]);

  useEffect(() => {
    if (state.data[state.questionId].answered) {
      return;
    }
    if (clicked === choicesData.answer) {
      dispatch({ type: "SET_CORRECT_SCORE" });
      dispatch({ type: "SET_CORRECT_INCORRECT", payload: "correct" });
      dispatch({ type: "ADD_TO_SCORE_SEQUENCE", payload: "correct" });
    } else if (clicked !== "") {
      dispatch({ type: "SET_CORRECT_INCORRECT", payload: "incorrect" });
      dispatch({ type: "ADD_TO_SCORE_SEQUENCE", payload: "incorrect" });
    }
  }, [clicked]);

  const handleQuestionClicked = (name: string) => {
    if (clicked !== "") {
      return;
    }
    setClicked(name);
  };

  return (
    <div
      className={`bd-quiz-question-choices__main ${
        choicesData.type === "double"
          ? "bd-quiz-question-choices-double"
          : "bd-quiz-question-choices-multi"
      }`}
    >
      {state.data &&
        choicesData.choices.map((item: any, index: number) => {
          return (
            <QuestionChoice
              onClick={handleQuestionClicked}
              key={choicesData.question}
              clicked={clicked}
              answer={choicesData.answer}
              type={choicesData.type}
              name={item.name}
              label={item.label}
            />
          );
        })}
    </div>
  );
};

export default QuestionChoices;
