import "./QuizButton.scss";
import clickSound from 'assets/quiz/sounds/navigation.mp3';
import useSound from 'use-sound';
import { Context } from "quiz-state-management/store";
import { useContext } from "react";

interface Props {
  theme: string;
  label: string;
  font?:string;
  shadow?:string;
  customClass?: string;
  onClick: Function;
}

const QuizButton = (props: Props) => {
  const [playSound] = useSound(clickSound);
  const [state] = useContext(Context);

  const handleOnClick = () => {
    props.onClick();
    if(state.bruitages){
    playSound();
    }
  }

  return (
    <div className={`${props.customClass} bd-quiz-button bd-quiz-button--${props.theme} bd-quiz-button bd-quiz-button--${props.shadow ? 'shadow' : ''} bd-quiz-button bd-quiz-button-font--${props.font}`} onClick={handleOnClick}>
        {props.label}
    </div>
  );
};

export default QuizButton;
