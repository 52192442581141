const audioElement = document.createElement('audio')
const sourceElement = document.createElement('source')
audioElement.muted = true;
audioElement.appendChild(sourceElement)

sourceElement.type = 'audio/mp3';

let audioPlaying: boolean = false;
const AudioPlayer = {
  audio: audioElement,
  play: (url: string, errorCallback?: Function) => {
    console.log('audioPlaying => ' + audioPlaying);
    if (!audioElement) return;
    sourceElement.src = url
    audioElement.muted = false;
    audioElement.load();
    audioPlaying = true;
    console.log(url);
    window.dispatchEvent(new Event('playingSound'));
    audioElement.play().catch((err) => {
      console.log(url);
      console.warn(err);
      audioPlaying = false;
      sourceElement.dispatchEvent(new Event('ended'));
      window.dispatchEvent(new Event('stopSound'));
      if (errorCallback) errorCallback(err);
    });
    if(url === 'dummy.mp3') audioElement.load();
  },
  pause:() => { 
    audioPlaying = false;
    audioElement.pause()
  },
  playing: audioPlaying,
}
audioElement.addEventListener('ended', () => {
  console.log('stop playing !!!');
  audioPlaying = false; 
  window.dispatchEvent(new Event('stopSound'));
});

Object.freeze(AudioPlayer);
export default AudioPlayer;