import { useEffect, useState } from 'react';
import './TableItem.scss';
import { TUserInfo } from 'types';
import UserDS from 'datastore/UserDS';

interface Props {
  data: {   
    column1?: any
    column2?: any
    column3?: any
  };
  userId?: string
  customClass?:string
}

const TableItem = (props: Props) => {
  const [currentUser, setCurrentUser] = useState<TUserInfo>();

  useEffect(() => {
    UserDS.getCurrentUserProfil((userInfo: TUserInfo) => {
      setCurrentUser(userInfo);
    })
  }, []);
  return (
    <div className={`${props.customClass} bd-quiz-table-item__main bd-quiz-table-item__main--${currentUser?.userId === props.userId ? 'current-user' : ''} `}>
      <div className="bd-quiz-table-item__item bd-quiz-table-item__item-1">{props.data.column1}</div>
      <div className="bd-quiz-table-item__item bd-quiz-table-item__item-2">{props.data.column2}</div>
      <div className="bd-quiz-table-item__item bd-quiz-table-item__item-3">{props.data.column3}</div>
    </div>
  );
};

export default TableItem;
