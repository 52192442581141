const rules: any = [
  {
    decrement: -15,
    skipped: -50,
    incorrect: -100,
    correct: 100,
    combo: 100,
  },
  {
    decrement: -11,
    skipped: -50,
    incorrect: -100,
    correct: 200,
    combo: 100,
  },
  {
    decrement: -7,
    skipped: -50,
    incorrect: -100,
    correct: 100,
    combo: 100,
  },
];

export const prepareQuizData = (data: any) => {

  const results: string[] = [];
  let temp: any = [];
  let id: string;
  data.question.map((item: any, index: number) => {
    if (index === 0) {
      id = item.name.slice(0, 3);
    }
    if (index > 0 && item.name.indexOf(id) === -1) {
      results.push(temp);
      temp = [];
      id = item.name.slice(0, 3);
    }
    temp.push(item);
    return item;
  });
  const randomPicks = results.map((item) => {
    return item[Math.floor(Math.random() * item.length)];
  });
  const finalData = randomPicks.map((pick: any, index: number) => {
    return data.question.filter((item: any) => {
      return item.name === pick.name;
    })[0];
  });
  const randomSorted = finalData.sort(() => Math.random() - 0.5);
  return randomSorted;
};

export const getChoices = (question: any) => {
  const keys: string[] = Object.keys(question).filter((key) => {
    return key.includes("choix");
  });
  const result: any = {};
  result.type = keys.length === 2 ? "double" : "multi";
  result.answer = question.answer;
  const choices: any = keys.map((item) => (
    { name: item, label: question[item] }
  ));
  result.choices = choices;
  return result;
};

export const calculateScore = (
  currentScore: number,
  sequence: string[],
  difficulty: number
) => {
  console.log(currentScore, sequence, difficulty);
  sequence.map((item, index) => {
    if (item === "skipped") {
      currentScore += rules[difficulty].skipped;
      console.log("skipped", currentScore);
    }
    if (item === "incorrect") {
      currentScore += rules[difficulty].incorrect;
      console.log("incorrect", currentScore);
    }
    if (item === "correct") {
      currentScore += rules[difficulty].correct;
      console.log("correct", currentScore);
    }
    if (index > 0) {
      if (sequence[index] === sequence[index - 1] && item === "correct") {
        currentScore += rules[difficulty].combo;
        console.log("combo", currentScore);
      }
    }
    return item;
  });
  return currentScore;
};

export const getScoreDecrement = (difficulty: number) => {
  return rules[difficulty].decrement;
};

export const convertToMinutes = (val: number) => {
  let minutes = Math.floor(val / 60);
  let seconds = val % 60;
  return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
}

export const getQuestionNumber = (difficulty: number) => {
  if (difficulty === 0) {
    return 10;
  }
  if (difficulty === 1) {
    return 15;
  }
  if (difficulty === 2) {
    return 20;
  }
};
