import "./QuizScores.scss";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import SelectorArrow from "components/quiz-shared/SelectorArrow";
import Divider from "components/quiz-shared/Divider";
import QuizButton from "components/quiz-shared/QuizButton";
import { Context } from "quiz-state-management/store";
import { Difficulty } from "quiz-state-management/constants";
import { ReactComponent as CupScores } from "assets/quiz/cup-scores.svg";
import TableItem from "./TableItem";

import QuizBackground from "components/quiz-shared/QuizBackground";
import { useState, useEffect } from "react";
import BaseDS from "datastore/BaseDS";
import { TCategory, TMainConfig } from "types";
import { useGA4React } from "ga-4-react";
import CategorySelector from "../QuizHome/CategorySelector";
import { subjectsIcons } from "quiz-state-management/constants";

const loadingCategory: TCategory = {
  id: "noID",
  mainId: "noID",
  categoryKey: -1,
  name: "loading",
  label: "Chargement ...",
  question: [],
};

const QuizScore = () => {
  const [state] = useContext(Context);
  const [scores, setScores] = useState<{ day: []; year: [] }>();
  const [categories, setCategories] = useState<TCategory[]>([loadingCategory]);
  const [section, setSection] = useState<number>(state.section);
  const [difficulty, setdifficulty] = useState<number>(state.difficulty);
  const history = useHistory();
  const ga: any = useGA4React();

  useEffect(() => {
    if (ga) {
      const title = document.title.split(" - ")[0] + " - Tableau des scores";
      if (title !== document.title) {
        document.title = title;
        ga.pageview(document.location.pathname, undefined, document.title);
      }
    }
  }, [ga]);

  useEffect(() => {
    BaseDS.getMainConfig((mainConfig: TMainConfig) => {
      setCategories(mainConfig.category ? mainConfig.category : []);
    });
  }, []);

  useEffect(() => {
    BaseDS.getScores(difficulty, section).then((data) => {
      setScores(data);
    });
  }, [section, difficulty]);

  const onSelectSection = (val: number) => {
    setSection(val);
  };
  const onSelectDifficulty = (val: number) => {
    setdifficulty(val);
  };
  const onBack = () => {
    history.push("/");
  };

  return (
    <div className="bd-quizscores__main">
      <QuizBackground theme="new-home--faded" />
      <div className="bd-quizscores__content">
        <div className="bd-quizscores__header"></div>
        <Divider height="20" />
        <div className="bd-quizhome__content-header">choix du thème</div>
        <Divider height="20" />
        <CategorySelector
          categories={categories}         
          onSelect={(val: number) => onSelectSection(val)}
          subjectsIcons={subjectsIcons}
        />
        <Divider height="20" />
        {/*<SelectorArrow
          values={[Difficulty[0].text, Difficulty[1].text, Difficulty[2].text]}
          theme="white"
          defaultValue={state.difficulty}
          onSelect={(val: number) => onSelectDifficulty(val)}
  /> */}       
        <div className="bd-quizscores__subcontainer">
          <div className="bd-quizscores__subcontainer-left">
            <div className="bd-quizscores__table-header">Champions du jour</div>
            <TableItem
              data={{ column1: "RANK", column2: "NOM", column3: "SCORE" }}
            />
            <div className="bd-quizscores__subcontainer-left__scores">
              {scores &&
                scores.day.map((item: any, index: number) => {
                  return (
                    <TableItem
                      key={index}
                      userId={item.userId || null}
                      data={{
                        column1: index + 1,
                        column2: item.name,
                        column3: item.score,
                      }}
                    />
                  );
                })}
            </div>
          </div>
          <div className="bd-quizscores__subcontainer-right">
            <div className="bd-quizscores__table-header">
              Champions {new Date().getFullYear()}
            </div>
            <TableItem
              data={{ column1: "RANK", column2: "NOM", column3: "SCORE" }}
            />
            <div className="bd-quizscores__subcontainer-right__scores">
              {scores &&
                scores.year.map((item: any, index: number) => {
                  return (
                    <TableItem
                      key={index + 1}
                      userId={item.userId || null}
                      data={{
                        column1: item.id || index + 1,
                        column2: item.name,
                        column3: item.score,
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div>
          <QuizButton
            customClass="bd-quizscores__back"
            shadow="true"
            font="big"
            theme="orange"
            onClick={onBack}
            label="retour"
          />
        </div>
      </div>
    </div>
  );
};

export default QuizScore;
