import Overlay from "components/quiz-shared/Overlay";
import Modal from "components/quiz-shared/Modal";
import QuizButton from "components/quiz-shared/QuizButton";
import { ReactComponent as GoldCup } from "assets/quiz/gold-cup.svg";
import { ReactComponent as ArrowRight } from "assets/quiz/arrow-right.svg";
import { ReactComponent as Submit } from "assets/quiz/submit.svg";
import { ReactComponent as Tick } from "assets/quiz/tick.svg";
import "./WonModal.scss";
import { useRef } from "react";
import { useEffect, useContext, useState } from "react";
import { Context } from "quiz-state-management/store";
import BaseDS from "datastore/BaseDS";
import {  
  calculateScore
} from "quiz-state-management/utils";

import { BeatLoader } from 'react-spinners';

interface Props {
  onReplay: Function;
  onGoHome: Function;
  onGoScores: Function;
  score: number;
}

const WonModal = (props: Props) => {
  const [state] = useContext(Context);
  const [position, setPosition] = useState<number>();
  const [userName, setUserName] = useState("");
  const inputRef = useRef<any>(null);
  const [finalScore, setFinalScore] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const registerScore = (callback: Function) => {
    const scoreDetails = {
      userName: userName,
      score: finalScore,
      level: state.difficulty,
      category: state.section,
    };   
    if (userName !== "") {
      BaseDS.completeGame(scoreDetails).then((scores) => {
        callback();
      });
    } else {
      callback();
    }
  };

   const handleSubmit = () => {
    setDisabled(true);
    inputRef.current.disabled = true;
    registerScore(() => {});
  }

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
     inputRef.current.focus();
     
     const calculatedScore = calculateScore(
      props.score,
      state.scoreSequence,
      state.difficulty
    );
    setFinalScore(calculatedScore);

    const scoreDetails = {
      score: calculatedScore,
      level: state.difficulty,
      category: state.section,
    };   
   
    BaseDS.positionForScore(scoreDetails).then((dataPosition: any) => {
      setPosition(dataPosition.position);
    });
  }, []);


  return (
    <div className="bd-quiz-won-modal__main">
      <Overlay>
        <Modal>
          <div className="bd-quiz-won-modal__container">
            <div className="bd-quiz-won-modal__header">GAGNÉ!</div>
            <div className="bd-quiz-won-modal__position-score">
              <div>
                <label>VOTRE SCORE</label>
                <div className="bd-quiz-won-modal__score">{`${finalScore}`}</div>
              </div>
              <div>
                <label>VOTRE POSITION</label>
                <div className="bd-quiz-won-modal__score">
                  {position ? position : <BeatLoader />}
                </div>
              </div>
            </div>
            <div className="bd-quiz-won-modal__form">
              <ArrowRight className="bd-quiz-won-modal__form-arrow" />
              {!disabled && <Submit onClick={handleSubmit} className={`bd-quiz-won-modal__form-submit`} />}
              {disabled && <Tick className={`bd-quiz-won-modal__form-tick`} />}
              <label>INSCRIVEZ VOTRE NOM</label>
              <div className="bd-quiz-won-modal__input-container">
                <input
                  ref={inputRef}
                  maxLength={6}
                  type="text"
                  onKeyDown={handleOnKeyDown}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
            </div>
            <div className="bd-quiz-won-modal__buttons">
              <div className="bd-quiz-won-modal__buttons-rejouer">
                <QuizButton
                  label="REJOUER"
                  theme="blue"
                  onClick={props.onReplay}
                />
              </div>
              <div className="bd-quiz-won-modal__buttons-accueil">
                <QuizButton
                  label="ACCUEIL"
                  theme="blue"
                  onClick={props.onGoHome}
                />
              </div>
            </div>
            <div className="bd-quiz-won-modal__button-scores__container">
              <GoldCup className="bd-quiz-won-modal__button-scores__icon" />
              <div className="bd-quiz-won-modal__button-scores__button">
                <QuizButton
                  label="TABLEAU DES SCORES"
                  theme="brown"
                  onClick={() => registerScore(props.onGoScores)}
                />
              </div>
            </div>
          </div>
        </Modal>
      </Overlay>
    </div>
  );
};

export default WonModal;
