import React, { createContext, useReducer } from "react";
import { useEffect } from "react";
import Reducer from "./reducer";
import { initialScore } from 'quiz-state-management/constants';

const loadLocalStorage = (key: string, defaultValue: any) => {
  try {
    const serializedState = localStorage.getItem("quiz-bdouin");
    if (serializedState === null) {
      return defaultValue;
    }
    return JSON.parse(serializedState)[key];
  } catch (err) {
    return null;
  }
};

const saveStateToLocalStorage = (state: {}) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("quiz-bdouin", serializedState);
  } catch {
    // ignore write errors
  }
};

const initialState: any = {
  mainConfigId: null,
  data: null,  
  mp3Loaded: false,
  userId: 8,
  dataLoaded: false,
  correctScore: 0,
  score: initialScore,
  scoreSequence: [],
  questionId: 0,
  section: loadLocalStorage("section", 0),
  difficulty: 0,
  voixQuestion: loadLocalStorage("voixQuestion", true),
  bruitages: loadLocalStorage("bruitages", true),
  mode: loadLocalStorage("mode", 0),
  theme: loadLocalStorage("theme", 0),
  paused: false,
  won_lost: "", // values an be '' 'won' or 'lost'
  playing: false,
  correct_incorrect: "", // values an be '' 'correct' or 'incorrect'
};

const Store: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    const stateToSave = {
      mainConfig: state.mainConfigId,
      bruitages: state.bruitages,
      competition: state.competition,
      difficulty: state.difficulty,
      mode: state.mode,
      section: state.section,
      theme: state.theme,
      voixQuestion: state.voixQuestion,
    };
    saveStateToLocalStorage(stateToSave);
  }, [state]);

  

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
