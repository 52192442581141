
import './Overlay.scss';

const Overlay = (props:any) => {
    return (
        <div className='bd-quiz-overlay__main'>           
            <div className='bd-quiz-overlay__content'>{props.children}</div>
        </div>
    )
}
export default Overlay;
