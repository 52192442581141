
import Overlay from 'components/quiz-shared/Overlay';
import Modal from 'components/quiz-shared/Modal';
import QuizButton from 'components/quiz-shared/QuizButton';
import {ReactComponent as PauseIcon } from 'assets/quiz/pause-icon.svg';
import "./PauseModal.scss";
interface Props { 
    onRestart: Function
    onGoHome: Function
}

const PauseModal = (props:Props) => {    
    return (
        <div className='bd-quiz-pause-modal__main'>
            <Overlay>  
                <Modal> 
                    <div className='bd-quiz-pause-modal__pause'>
                    <div><PauseIcon className='bd-quiz-pause-modal__pause-icon'/></div>
                    <div className='bd-quiz-pause-modal__pause-text'>EN PAUSE</div>
                    <div className='bd-quiz-pause-modal__pause-buttons'>
                        <div className='bd-quiz-pause-modal__pause-buttons-reprendre'><QuizButton label="REPRENDRE" theme="blue" onClick={props.onRestart}/></div>
                        <div className='bd-quiz-pause-modal__pause-buttons-accueil'><QuizButton label="ACCUEIL" theme="grey" onClick={props.onGoHome}/></div>
                    </div>
                    </div>
                </Modal> 
            </Overlay>
        </div>
    )
}

export default PauseModal;