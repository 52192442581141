import { emojiCountryCode } from './EmojiToCountry'

export default class TextDecoder {
  static formatText = (text: any) => {
    if (!text) return '';
    const splittedText: string[] = text.split(/\n/g);
    return splittedText.map((match: string, index: number) => (<span key={index}>{match}{index < splittedText.length - 1 && (<br />)}</span>))
  }
  static surroundEmojis = (text: string, prefix?: string | number) => {
    const reg = new RegExp(/([\u1000-\uFFFF]+)/, 'gi');
    const parts = text.split(reg);
    return <>{parts.map(part => (part.match(reg) ?
      <span className={'qz-emoji' + prefix}>
        {navigator.userAgent.indexOf("Win") !== -1 && prefix === 2 ? (
        <img src={'/flags/' + emojiCountryCode(part) + '.png'} alt={emojiCountryCode(part)} />
        ) : (<>{part}</>)}
      </span> :
      TextDecoder.formatText(part)))}</>;
  }
}