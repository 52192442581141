import { ReactComponent as RemainingTime } from "assets/quiz/remaining-time.svg";
import { ReactComponent as Objectif } from "assets/quiz/objectif.svg";
import { ReactComponent as Logo } from "assets/quiz/quiz-bdouin-logo-white.svg";
import "./QuizHeader.scss";
import { getQuestionNumber, convertToMinutes } from "quiz-state-management/utils";
import { Context } from "quiz-state-management/store";
import { useContext, useEffect, useState } from "react";
import { Difficulty } from "quiz-state-management/constants";

const QuizHeader = () => {
  const [state] = useContext(Context);
  const [remaining, setRemaining] = useState(Difficulty[state.difficulty].duration)
  
  useEffect(() => {
    let interval: any;
     if(state.playing){
        interval = setInterval(() => {
            if(!state.paused){
            setRemaining(prev => prev-1);   
            }        
          }, 1000);
     } else {
        clearInterval(interval); 
     }
     return () => {
        clearInterval(interval);
      };
  },[state.playing, state.paused])

  return (
    <div
      className={`bd-quiz-header__main ${
        state.mode === 1 ? "bd-quiz-header--invisible" : ""
      }`}
    >
      <div className="bd-quiz-header__objectif">
        <Objectif className="bd-quiz-header__objectif-background"/>
        <div className="bd-quiz-header__objectif-text">
          {`${state.correctScore < 9 ? '0' : ''}${state.correctScore}/${getQuestionNumber(state.difficulty)}`}
        </div>{" "}
      </div>
      <Logo className="bd-quiz-header__logo"/>
      <div className="bd-quiz-header__remaining-time">
        <RemainingTime className="bd-quiz-header__remaining-time-background"/>
        <div className="bd-quiz-header__remaining-time-text">{convertToMinutes(remaining)}</div>
      </div>
    </div>
  );
};

export default QuizHeader;
