import "./ThemeSelector.scss";
import { useContext, useState } from "react";
import { Themes } from "quiz-state-management/constants";
import { Context } from "quiz-state-management/store";
import clickSound from "assets/quiz/sounds/navigation.mp3";
import useSound from "use-sound";

interface Props { 
  onSelect: Function;
}

const ThemeSelector = (props: Props) => {
  const [state] = useContext(Context);
  const [playSound] = useSound(clickSound);
  const [selectedTheme, setSelectedTheme] = useState(state.theme);


  const setTheme = (id:number) => {
    props.onSelect(id);
    setSelectedTheme(id)
    if (state.bruitages) {
      playSound();
    }
  }

  return (
    <div className={`bd-quiz-themeselector__main bd-quiz-themeselector__main`}>
      <div className={"bd-quiz-themeselector__previews-container"}>  
       {Themes.map((theme, index) => {
           let ThemePreview = theme.preview;
           return (            
             <ThemePreview 
             key={index}
             onClick={() => setTheme(index)}
             className={`bd-quiz-themeselector__preview ${index === selectedTheme ? 'bd-quiz-themeselector__preview--selected' : ''}`}        
             />            
           )
       })}
      </div>
    </div>
  );
};

export default ThemeSelector;
