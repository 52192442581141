import "./BoatAnimation.scss";
import { ReactComponent as Wave1 } from "assets/quiz/wave1.svg";
import { ReactComponent as Wave2 } from "assets/quiz/wave2.svg";
import { ReactComponent as Wave3 } from "assets/quiz/wave3.svg";
import { ReactComponent as Flag3 } from "assets/quiz/flag.svg";
import { useRef } from "react";
import { gsap, Sine, Linear } from "gsap";
import { useEffect, useContext } from "react";
import useMeasure from 'react-use-measure'
import { Context } from "quiz-state-management/store";
import { Themes, Difficulty } from "quiz-state-management/constants";
import { getQuestionNumber } from "quiz-state-management/utils";
import mergeRefs from "react-merge-refs";

const BoatAnimation = () => {
  const [state, dispatch] = useContext(Context); 

  const BoatPlayer = Difficulty[state.difficulty].boats[0];
  const BoatComputer = Difficulty[state.difficulty].boats[1];

  const boatPlayerRef = useRef(null);
  const boatComputerRef = useRef(null);

  const wave1Ref = useRef(null);
  const wave2Ref = useRef(null);
  const wave3Ref = useRef(null);
  const flagRef = useRef(null);
  const mainRef = useRef(null);

  const [ mainMeasureRef, mainBounds]  = useMeasure();
  const [ boatComputerMeasureRef, boatComputerBounds] = useMeasure();
  const [ boatPlayerMeasureRef, boatPlayerBounds] = useMeasure();
  const [ wave1MeasureRef, wave1Bounds] = useMeasure();

  const animation: any = useRef();
  const playerBoat: any = useRef();
  const playerBoatIncrement: any = useRef(0);

  const handleOnResize = () => {
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener("resize", handleOnResize);
    return () => {
      window.removeEventListener("resize", handleOnResize);
    };
  }, []);

  useEffect(() => {
    if (animation.current) {
      if (state.paused) {
        animation.current.pause();
        if (playerBoat.current) {
          playerBoat.current.pause();
        }
      } else {
        animation.current.play();
        if (playerBoat.current) {
          playerBoat.current.play();
        }
      }
    }
  }, [state.paused]);

  const doComputerWon = () => {
    dispatch({ type: "WON_LOST", payload: "lost" });
    killAnimations();
  };
  const doPlayerWon = () => {
    dispatch({ type: "WON_LOST", payload: "won" });
    killAnimations();
  };

  const movePlayerBoat = () => {   
    const checkIfWon = () => {
      if (
        playerBoatIncrement.current >
        mainBounds.width - boatPlayerBounds.width - 5
      ) {
        doPlayerWon();
      } else if (state.questionId < state.data.length - 1) {
        dispatch({ type: "SET_NEXT_QUESTION" });
      } else if (state.questionId === state.data.length - 1) {
        dispatch({ type: "WON_LOST", payload: "lost" });
        killAnimations();
      }
    };

    playerBoatIncrement.current +=
      (mainBounds.width - boatPlayerBounds.width) /
      getQuestionNumber(state.difficulty)!;

    if (playerBoat.current && playerBoat.current.isActive()) {
      return;
    }
    playerBoat.current = gsap.to(boatPlayerRef.current, {
      x: `${playerBoatIncrement.current}`,
      duration: 2,
      ease: Sine.easeInOut,
      onComplete: checkIfWon,
      overwrite: "auto",
    });
  };

  useEffect(() => {
    let timeOut: any;
    if (state.correct_incorrect === "correct") {
      movePlayerBoat();
    }
    if (
      state.correct_incorrect === "incorrect" &&
      state.questionId < state.data.length - 1
    ) {
      timeOut = setTimeout(() => {
        dispatch({ type: "SET_NEXT_QUESTION" });
      }, 2000);
    } else if (state.questionId === state.data.length - 1) {
      dispatch({ type: "WON_LOST", payload: "lost" });
      killAnimations();
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [state.correct_incorrect]);

  const killAnimations = () => {
    animation.current.kill();
    if (playerBoat.current) {
      playerBoat.current.kill();
    }
  };

  useEffect(() => {
    if (
      mainBounds.width > 0 && boatComputerBounds.width > 0 && boatPlayerBounds.width > 0 && wave1Bounds.width > 0
    ) {
      if(animation.current && animation.current.isActive()){
        return;
      }  
      animation.current = gsap
        .timeline()
        .to(boatComputerRef.current, {
          x: mainBounds.width - boatComputerBounds.width,
          duration: Difficulty[state.difficulty].duration,
          ease: Linear.easeNone,
          onComplete: doComputerWon,
        })
        .to(
          boatComputerRef.current,
          {
            y: 5,
            duration: 1,
            ease: Sine.easeInOut,
            yoyo: true,
            repeat: -1,
            delay: Math.random(),
            onComplete: doComputerWon,
          },
          0
        )
        .fromTo(
          boatComputerRef.current,
          {
            rotation: -1,
            transformOrigin: "50% 50%",
          },
          {
            transformOrigin: "50% 50%",
            rotation: 3,
            duration: 2,
            ease: Sine.easeInOut,
            yoyo: true,
            repeat: -1,
            delay: Math.random(),
          },
          0
        )
        .to(
          boatPlayerRef.current,
          {
            y: 5,
            duration: 1,
            ease: Sine.easeInOut,
            yoyo: true,
            repeat: -1,
            delay: Math.random(),
          },
          0
        )
        .fromTo(
          boatPlayerRef.current,
          {
            rotation: -3,
            transformOrigin: "50% 50%",
          },
          {
            transformOrigin: "50% 50%",
            rotation: 1,
            duration: 2,
            ease: Sine.easeInOut,
            yoyo: true,
            repeat: -1,
            delay: Math.random(),
          },
          0
        )
        .to(
          wave1Ref.current,
          {
            x: -mainBounds.width,
            duration: 15,
            ease: Linear.easeNone,
            repeat: -1,
          },
          0
        )
        .to(
          wave1Ref.current,
          {
            y: 3,
            duration: 1,
            ease: Sine.easeInOut,
            yoyo: true,
            repeat: -1,
          },
          0
        )
        .to(
          wave2Ref.current,
          {
            x: -mainBounds.width,
            duration: 30,
            ease: Linear.easeNone,
            repeat: -1,
          },
          0
        )
        .to(
          wave2Ref.current,
          {
            y: 3,
            duration: 1,
            ease: Sine.easeInOut,
            yoyo: true,
            repeat: -1,
          },
          0
        )
        .to(
          wave3Ref.current,
          {
            x: -mainBounds.width,
            duration: 60,
            ease: Linear.easeNone,
            repeat: -1,
          },
          0
        )
        .to(
          wave3Ref.current,
          {
            y: 3,
            duration: 1,
            ease: Sine.easeInOut,
            yoyo: true,
            repeat: -1,
          },
          0
        )
        .to(
          flagRef.current,
          {
            y: 5,
            duration: 1,
            ease: Sine.easeInOut,
            yoyo: true,
            repeat: -1,
            delay: 0.5,
          },
          0
        )
        .to(
          flagRef.current,
          {
            left: -50,
            duration: 5,
            ease: Linear.easeNone,
          },
          0
        );
    }
    return () => {
      if (animation.current) {
        animation.current.kill();
      }
      if (playerBoat.current) {
        playerBoat.current.kill();
      }
    };
  }, [
    mainBounds.width,
    boatComputerBounds.width,
    boatPlayerBounds.width,
    wave1Bounds.width
  ]);

  return (
    <div ref={mergeRefs([mainRef, mainMeasureRef])} className="bd-quiz-boat-animation__main">
      <div className="bd-quiz-boat-animation_container">
        <Wave3
          ref={wave3Ref}
          className={`bd-quiz-boat-animation__wave3 bd-quiz-boat-animation__wave3--${
            Themes[state.theme].name
          }`}
        />

        <BoatPlayer
          ref={mergeRefs([boatPlayerRef, boatPlayerMeasureRef])}
          className="bd-quiz-boat-animation__boat-player"
        />

        <BoatComputer
          ref={mergeRefs([boatComputerRef, boatComputerMeasureRef])}
          className="bd-quiz-boat-animation__boat-computer"
        />

        <Wave2
          ref={wave2Ref}
          className={`bd-quiz-boat-animation__wave2 bd-quiz-boat-animation__wave2--${
            Themes[state.theme].name
          }`}
        />
        <Flag3 ref={flagRef} className="bd-quiz-boat-animation__flag"/>
       
        <Wave1
          ref={mergeRefs([wave1Ref, wave1MeasureRef])}
          className={`bd-quiz-boat-animation__wave1 bd-quiz-boat-animation__wave1--${
            Themes[state.theme].name
          }`}
        />
      </div>
    </div>
  );
};

export default BoatAnimation;
