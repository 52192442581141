import "./ScorePreview.scss";
import QuizButton from "components/quiz-shared/QuizButton";
import ScoreItem from "components/quiz-screens/QuizHome/ScorePreview/ScoreItem";
import { useHistory } from "react-router-dom";
import { TCategory } from "types";

interface Props {
  categories: TCategory[];
}

const ScorePreview = (props: Props) => {
  const history = useHistory();
 
  const onClickScores = () => {
    history.push("/scores");
  };

  return (
    <div className={`bd-quiz-scorepreview__main`}>  
      <div className={`bd-quiz-scorepreview__top-header`}>Meilleurs scores de la semaine</div>  
      <hr className={`bd-quiz-scorepreview__hr`}/>
      <ScoreItem
        categories={props.categories}
        section={0}
      />
       <ScoreItem
        categories={props.categories}
        section={1}
      />
      <ScoreItem
        categories={props.categories}
        section={2}
      />
      <ScoreItem
        categories={props.categories}
        section={3}
      />
      <QuizButton
        shadow="true"       
        theme="white-scores"
        onClick={onClickScores}
        label="Accéder a tous les scores"
        customClass={"bd-quiz-scorepreview__all-scores-button"}
      />
    </div>
  );
};

export default ScorePreview;
