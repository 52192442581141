import React from 'react';

import './App.scss';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoadingPage from 'components/elements/LoadingPage';

import QuizComponent from 'components/quiz-screens/Quiz';
import QuizHomeComponent from 'components/quiz-screens/QuizHome';
import ScoresComponent from 'components/quiz-screens/QuizScores';
import Store from 'quiz-state-management/store';
import Secured from 'Secured';
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import BlockerContextProvider from "components/utils/BlockerContext";
import UserAnnouncement from 'components/elements/common/phoneblocked/UserAnnouncement';
import UserContextProvider from 'components/utils/UserContext';
import MainContextProvider from 'components/utils/MainContext';
import MainLayout from 'components/layout/MainLayout';

const signUpFields = [
  {
    type: "name",
    label: "Name",
    placeholder: "Enter your name",
    hint: null,
    required: true,
  },
  {
    type: "email",
    label: "Email Address *",
    required: true,
  },
  {
    type: "password",
    label: "Password *",
    required: true,
  },
];

function App() {

  return (<>
    <UserAnnouncement />
    <UserContextProvider>
      <BlockerContextProvider>
        <MainContextProvider>
          <Secured>
            <Store>
              <React.Suspense fallback={<LoadingPage />}>
                <Router>
                  <Switch>
                    <Route path="/" exact render={QuizHome} />
                    <Route path="/quiz" exact render={Quiz} />
                    <Route path="/scores" exact render={Scores} />
                    {process.env.REACT_APP_DOMAIN_NAME === 'localhost' && (
                      <Route path="/login" exact render={Login} />
                    )}
                  </Switch>
                </Router>
              </React.Suspense>
            </Store>
          </Secured>
        </MainContextProvider>
      </BlockerContextProvider>
    </UserContextProvider>
  </>);
}

export default App;

const Quiz = (props: any) => (
  <MainLayout className="bd-quiz-background">
    <QuizComponent {...props} />
  </MainLayout>
);

const QuizHome = (props: any) => (
  <MainLayout className="bd-quizhome-background">
    <QuizHomeComponent {...props} />
  </MainLayout>
);

const Scores = (props: any) => (
  <MainLayout className="bd-quizscores-background">
    <ScoresComponent {...props} />
  </MainLayout>
);

const Login = () => (
  <AmplifyAuthenticator usernameAlias="email">
    <AmplifySignUp
      usernameAlias="email"
      formFields={signUpFields}
      slot="sign-up"
    />
  </AmplifyAuthenticator>
);
