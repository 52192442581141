import "./QuestionInfo.scss";
import { ReactComponent as Buoy } from "assets/quiz/buoy.svg";
import { Context } from "quiz-state-management/store";
import { useState, useContext } from "react";
import { useEffect } from "react";

interface Props {
  content: string 
  showInfo: boolean 
}
const QuestionInfo = (props: Props) => {

    const [showInfo, setShowInfo ] = useState(false);
    const [state] = useContext(Context);

    const handleOnClick = () => {
        setShowInfo(true)
    }

    useEffect(() => {
        setShowInfo(false);
    }, [state.questionId])

  return (
    <div className="bd-quiz-question-info__main">
      { (props.showInfo || showInfo) && <div className="bd-quiz-question-info__content">{props.content}</div> }
      <div
        className="bd-quiz-question-info__icon-container"
        onClick={handleOnClick}
      >
        <Buoy className="bd-quiz-question-info__icon" />
      </div>
    </div>
  );
};

export default QuestionInfo;
