import "./Quiz.scss";
import correctSound from "assets/quiz/sounds/correct.mp3";
import incorrectSound from "assets/quiz/sounds/incorrect.mp3";
import wonSound from "assets/quiz/sounds/won.mp3";
import lostSound from "assets/quiz/sounds/lost.mp3";
import useSound from "use-sound";
import AudioPlayer from "components/utils/AudioPlayer";
import QuizHeader from 'components/quiz-screens/Quiz/QuizHeader';

import { useContext, useEffect, useState } from "react";

import BoatAnimation from "./BoatAnimation";
import { Context } from "quiz-state-management/store";
import GoScreen from "./GoScreen";
import LostModal from "./LostModal";
import PauseModal from "./PauseModal";
import QuestionChoices from "./QuestionChoices";
import QuestionContainer from "./QuestionContainer";
import QuestionInfo from "./QuestionInfo";
import QuizBackground from "components/quiz-shared/QuizBackground";
import React from "react";
import { Themes, initialScore } from "quiz-state-management/constants";
import WonModal from "./WonModal";
import {
  prepareQuizData,
  getScoreDecrement
} from "quiz-state-management/utils";
import { useHistory } from "react-router-dom";
import BaseDS from "datastore/BaseDS";
import { TCategory, TMainConfig } from "types";
import FileDS from "datastore/FileDS";
import { useRef } from "react";
import { useGA4React } from 'ga-4-react';

const Quiz = () => {
  const [state, dispatch] = useContext(Context);
  const [showInfo, setShowInfo] = useState(false);
  const [category, setCategory] = useState<TCategory>();
  const [goClicked, setGoClicked] = useState(state.mode === 0 ? true : false);
  const history = useHistory();

  const [playCorrect] = useSound(correctSound);
  const [playIncorrect] = useSound(incorrectSound);
  const [playWon] = useSound(wonSound);
  const [playLost] = useSound(lostSound);

  const mp3QuestionList = useRef<Array<string>>([]);
  const mp3Count = useRef(0);
  const dataInitialised = useRef(false);
	const ga: any = useGA4React();
  const scoreToDecrement = useRef(initialScore)

  const reset = () => {
    dispatch({ type: "RESET_QUIZ" });
    console.log("resetting quiz");
  };

  useEffect(() => {
    reset();
    console.log(mp3QuestionList.current);
    dataInitialised.current = false;
    return () => {
      Quiz.shouldDownload = false;
      mp3Count.current = 0;
    }
  }, []);

  const createQuizData = (data?: TCategory) => {
    if (data) {
      const quizData = prepareQuizData(data);
      dataInitialised.current = true;
      dispatch({ type: "SET_QUIZ_DATA", payload: quizData });
    }
  };

	useEffect(() => {
		if (ga) {
			const title = document.title.split(' - ')[0] + ' - ' + (state.mode === 0 ? 'Compétition' : 'Entrainement');
			if (title !== document.title) {
				document.title = title;
				ga.pageview(document.location.pathname, undefined, document.title);
			}
		}
	}, [ga]);

  const initialiseData = () => {
    BaseDS.getMainConfig((mainConfig: TMainConfig) => {
      if (mainConfig && mainConfig.category[state.section]) {
        BaseDS.getFullCategoryById(mainConfig.category[state.section].id).then(
          (categoryData) => {
            setCategory(categoryData);
            createQuizData(categoryData);
            if (!goClicked) {
              dispatch({ type: "SET_PLAYING", payload: true });
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    if (state.data === null) {
      console.log("initialising data");
      initialiseData();
    }
  }, [state.data]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        reset();
        AudioPlayer.pause();
      }
    };
  }, [history]);

  useEffect(() => {
    if (state.won_lost !== "") {
      dispatch({ type: "SET_PLAYING", payload: false });
    }
  }, [state.won_lost, dispatch]);

  useEffect(() => {
    if (state.won_lost === "won") {
      if (state.bruitages) {
        playWon();
      }
    }
    if (state.won_lost === "lost") {
      if (state.bruitages) {
        playLost();
      }
    }
  }, [state.won_lost]);

  useEffect(() => {
    if (state.correct_incorrect === "correct") {
      state.data[state.questionId].answered = true;
      if (state.bruitages) {
        playCorrect();
      }
    }

    if (state.correct_incorrect === "incorrect") {
      state.data[state.questionId].answered = false;
      if (state.bruitages) {
        playIncorrect();
      }
    }

    if (
      state.correct_incorrect === "correct" ||
      state.correct_incorrect === "incorrect"
    ) {
      setShowInfo(true);
    }
  }, [state.correct_incorrect]);

  const playMp3 = () => {
    if (
      state.voixQuestion &&
      mp3QuestionList.current[state.questionId] !== undefined
    ) {
      console.log("mp3 to play =>", mp3QuestionList.current[state.questionId]);
      AudioPlayer.play(mp3QuestionList.current[state.questionId], () => {
        console.log("error playing sound");
      });
    }
  };

  useEffect(() => {
    let scoreInterval: NodeJS.Timeout | any;    
    const decrement = getScoreDecrement(state.difficulty);
    if (!state.playing || state.paused || state.won_lost === 'won' || state.won_lost === 'lost') {
      clearInterval(scoreInterval);      
      return;
    }
    scoreInterval = setInterval(() => {
      if (scoreToDecrement.current > 0) {
        scoreToDecrement.current = scoreToDecrement.current += decrement;
        console.log(scoreToDecrement.current)
      }    
    }, 100);
    return () => clearInterval(scoreInterval);
  }, [state.playing, state.paused, state.won_lost]);

  const addToMp3List = (url: string) => {
    mp3Count.current++;
    if (mp3Count.current > 3) {
      dispatch({ type: "SET_MP3_LOADED" });
    }
    mp3QuestionList.current.push(url);   
      downloadMp3s();
  };

  const downloadMp3s = () => {
    if (Quiz.shouldDownload && state.data && mp3Count.current <= state.data.length - 1) {
      FileDS.downloadFile(
        state.data[mp3Count.current].audioKey,
        (response: { Body: Blob }) => {
          const blob = response.Body;
          const blobUrl = URL.createObjectURL(blob);
          addToMp3List(blobUrl);
        },
        (error: any) => {
          addToMp3List("");
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    if (state.data && dataInitialised.current === true) {
      mp3QuestionList.current = [];
      console.log("intialising mp3 downloads");
      Quiz.shouldDownload = true; 
      downloadMp3s();
    }
  }, [state.data]);

  useEffect(() => {
    setShowInfo(
      state.data &&
        state.mode === 1 &&
        state.data[state.questionId].hasOwnProperty("answered")
    );
    if (state.playing) {
      playMp3();
    }
  }, [state.questionId]);

  const onGo = () => {
    playMp3();
    setGoClicked(false);
    dispatch({ type: "SET_PLAYING", payload: true });
  };

  useEffect(() => {
    if (!state.playing || state.paused) {
      AudioPlayer.pause();
    }
  }, [state.playing, state.paused]);

  const goHome = () => {
    reset();
    history.push("/");
  };

  const restart = () => {
    dispatch({ type: "SET_PAUSE", payload: false });
  };

  const replay = () => {
    reset();
    mp3Count.current = 0;
    createQuizData(category);
    setGoClicked(true);
    history.push("/quiz");
  };

  const goScores = () => {
    history.push("/scores");
  };

  return (
    <React.StrictMode>
      <QuizBackground
        theme={state.mode === 0 ? Themes[state.theme].name : "entrainement"}
      />
      <div className="bd-quiz__main">
        {goClicked && <GoScreen onGo={onGo} />}
        {state.paused && <PauseModal onRestart={restart} onGoHome={goHome} />}
        {state.won_lost === "won" && (
          <WonModal score={scoreToDecrement.current} onReplay={replay} onGoHome={goHome} onGoScores={goScores} />
        )}
        {state.won_lost === "lost" && (
          <LostModal
            onReplay={replay}
            onGoHome={goHome}
            onGoScores={goScores}
          />
        )}
        {state.playing && (
          <div className="bd-quiz__content">
            <QuizHeader/>
            <QuestionContainer playMp3={playMp3} />
            <QuestionChoices />
            {state.data && state.mode === 1 && (
              <QuestionInfo
                showInfo={showInfo}
                content={state.data[state.questionId].preuve}
              />
            )}
            {state.data && state.mode === 0 && <BoatAnimation />}
          </div>
        )}
      </div>
    </React.StrictMode>
  );
};

export default Quiz;


Quiz.shouldDownload = false; 