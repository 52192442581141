import "./QuizHome.scss";
import { useHistory } from "react-router-dom";
import { ReactComponent as HeaderImage } from "assets/quiz/header-image.svg";
import React, { useContext, useEffect, useState } from "react";
import SelectorArrow from "components/quiz-shared/SelectorArrow";
import SelectorSlider from "components/quiz-shared/SelectorSlider";
import Divider from "components/quiz-shared/Divider";
import QuizButton from "components/quiz-shared/QuizButton";
import { Context } from "quiz-state-management/store";
import { Difficulty, Modes } from "quiz-state-management/constants";
import ThemeSelector from "./ThemeSelector";
import QuizBackground from "components/quiz-shared/QuizBackground";
import BaseDS from "datastore/BaseDS";
import { TCategory, TMainConfig } from "types";
import { useGA4React } from "ga-4-react";
import CategorySelector from "./CategorySelector";
import { UserContext } from "components/utils/UserContext";
import { BlockerContext } from "components/utils/BlockerContext";
import ScorePreview from "components/quiz-screens/QuizHome/ScorePreview";
import { subjectsIcons } from "quiz-state-management/constants";

export const highestNumber = 9999999999999;

export const dateToNumber = (dateString: string | undefined) => {
  const stringWithouDash = removeAllDash(dateString);
  return stringWithouDash ? parseInt(stringWithouDash) : highestNumber;
};

const removeAllDash = (value: string | undefined) => {
  return value ? value.replace(/-/g, "") : value;
};

export const isPublished = (stringDate: string | undefined) => {
  const publicateDateNumber = dateToNumber(stringDate);
  return (
    !isNaN(publicateDateNumber) &&
    publicateDateNumber !== highestNumber &&
    publicateDateNumber < dateToNumber(new Date().toISOString())
  );
};

const loadingCategory: TCategory = {
  id: "noID",
  mainId: "noID",
  categoryKey: -1,
  name: "loading",
  label: "Chargement ...",
  question: [],
};

const QuizHome = (props: any) => {
  const { isUserAuthenticated } = useContext(UserContext);
  const [state, dispatch] = useContext(Context);

  const { setBlock, setItemId } = useContext(BlockerContext);
  const { userHasAccess } = useContext(UserContext);

  const [categories, setCategories] = useState<TCategory[]>([loadingCategory]);
  const history = useHistory();
  const ga: any = useGA4React();

  useEffect(() => {
    BaseDS.getMainConfig((mainConfig: TMainConfig) => {
      dispatch({ type: "SET_DATA_LOADED", payload: mainConfig.id });
      dispatch({ type: "RESET_QUIZ" });
      const nbPreviousCatString = localStorage.getItem("nb-previous-category");
      const nbPreviousCat = parseInt(nbPreviousCatString || "0");
      mainConfig.category.sort(
        (a, b) =>
          dateToNumber(a.publicationDate) - dateToNumber(b.publicationDate)
      );
      setCategories(mainConfig.category ? mainConfig.category : []);
      const freemiumList =
        mainConfig.category.length &&
        mainConfig.category.filter((i: any) => i.freemium);
      if (!isUserAuthenticated && freemiumList && freemiumList.length) {
        const selectedSection = mainConfig.category.findIndex(
          (c) => c.id === freemiumList[0].id
        );
        setTimeout(() => {
          dispatch({ type: "SET_SECTION", payload: selectedSection });
        }, 500);
      } else if (nbPreviousCat < mainConfig.category.length) {
        setTimeout(() => {
          dispatch({
            type: "SET_SECTION",
            payload: mainConfig.category.length - 1,
          });
          localStorage.setItem(
            "nb-previous-category",
            mainConfig.category.length.toString()
          );
        }, 500);
      }
    });
  }, []);

  useEffect(() => {
    if (ga) {
      const title = document.title.split(" - ")[0] + " - Accueil";
      if (title !== document.title) {
        document.title = title;
        ga.pageview(document.location.pathname, undefined, document.title);
      }
    }
  }, [ga]);

  const onSelectSection = (val: number) => {
    dispatch({ type: "SET_SECTION", payload: val });
  };
  const onSelectDifficulty = (val: number) => {
    dispatch({ type: "SET_DIFFICULTY", payload: val });
  };
  const onSelectVoixQuestion = (val: boolean) => {
    dispatch({ type: "SET_VOIX_QUESTION", payload: val });
  };
  const onSelectBruitages = (val: boolean) => {
    dispatch({ type: "SET_BRUITAGES", payload: val });
  };
  const onSelectMode = (val: number) => {
    dispatch({ type: "SET_MODE", payload: val });
  };
  const onSelectTheme = (val: number) => {
    dispatch({ type: "SET_THEME", payload: val });
  };
  const handleStart = () => {
    if (state.dataLoaded) {
      const cat = categories[state.section];
      if (!userHasAccess(cat.id, cat.freemium) && !cat.freemium) {
        setItemId(cat.id);
        setBlock(true);
        return false;
      } else if (isPublished(cat.publicationDate)) {
        history.push("/quiz");
      }
    }
  };

  return (
    <div className="bd-quizhome__main">
      <QuizBackground theme="new-home--faded" />
      <div className="bd-quizhome__content">
        <Divider height="20" />
        <div className="bd-quizhome__content-header">choix du thème</div>
        <Divider height="20" />
        <CategorySelector
          categories={categories}
          subjectsIcons={subjectsIcons}
          defaultValue={state.section}
          onSelect={(val: number) => onSelectSection(val)}
        />
        <hr/>       
        <div className="bd-quizhome__content-header">choix du décor</div> 
          <ThemeSelector           
            onSelect={(val: number) => onSelectTheme(val)}
          />
           <hr/>
          <div className="bd-quizhome-mode-container">
          <QuizButton customClass={`bd-quizhome-mode-button ${state.mode === 0 ? 'bd-quizhome-mode-button--selected' : ''}`} label="Mode compétition" theme="green-dark" onClick={() => onSelectMode(0)} />
          <Divider width="20" />
          <QuizButton customClass={`bd-quizhome-mode-button ${state.mode === 1 ? 'bd-quizhome-mode-button--selected' : ''}`} label="Mode entraînement" theme="green-dark" onClick={() => onSelectMode(1)} />
          </div>
          <hr/> 
        <div className={`${state.dataLoaded ? "" : "bd-quizhome--not-ready"}`}>
          <QuizButton customClass="bd-quizhome-commencer" label="Commencer" theme="green-light" onClick={handleStart} />
        </div>
      </div>
      <ScorePreview categories={categories}/>
    </div>
  );
};

export default QuizHome;
