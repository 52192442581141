import "./HomeLeftArea.scss";
import { ReactComponent as QuizIcon } from "../../assets/v2/qz-home-left-logo.svg";
import HooPowAppMenu from "components/elements/common/internalrouter/HooPowAppMenuV2";
import { useContext } from "react";
import FreemiumAvailable from "components/elements/FreemiumAvailable";
import { UserContext } from "components/utils/UserContext";

const QuizLeftAreaV2 = () => {
	const { freemiumEnabled, isUserAuthenticated } = useContext(UserContext);
	return (
		<>
			{freemiumEnabled && !isUserAuthenticated && isUserAuthenticated !== undefined && (
				<FreemiumAvailable />
			)}
			<div className="ms-home-icon"> <QuizIcon /> </div>
			<HooPowAppMenu />
		</>
	);
};

export default QuizLeftAreaV2;