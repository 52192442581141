import { forwardRef, useEffect, useState } from 'react';
import './LoadingPage.scss';
import animationData from '../../assets/waiting.json';
import { ReactComponent as Logo } from '../../assets/kalimat_logo_vec.svg';
import { Player } from '@lottiefiles/react-lottie-player'

let imageToLoad: any = {};
let imageLoaded: any = {};

const LoadingPage = forwardRef((props: any, ref: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [imageFinishToLoad, setImageFinishToLoad] = useState<boolean>(false);
  const { setLoadPageDisplayed } = props;

  useEffect(() => {
    const timer = setTimeout(() => { setLoading(false); }, 10000);

    if (!loading) {
      clearTimeout(timer)
    }

    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Object.keys(imageToLoad).length === Object.keys(imageLoaded).length && !imageFinishToLoad) {
        setLoading(false);
        
      }
    }, 3000);
    if (!loading) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [loading, imageFinishToLoad]);

  useEffect(() => {
    if (imageFinishToLoad) {
      
      const timer = setTimeout(() => setLoading(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [imageFinishToLoad]);

  useEffect(() => {
    if (setLoadPageDisplayed) {
      setLoadPageDisplayed(loading);
    }
  }, [loading, setLoadPageDisplayed]);

  useEffect(() => {

    const imageLoadEvent = ((event: CustomEvent) => {
      imageToLoad[event.detail] = true;
    }) as EventListener;

    const imageLoadedEvent = ((event: CustomEvent) => {
      imageLoaded[event.detail] = true;
      setImageFinishToLoad(Object.keys(imageToLoad).length === Object.keys(imageLoaded).length);
    }) as EventListener;

    window.addEventListener('imageLoad', imageLoadEvent);
    window.addEventListener('imageLoaded', imageLoadedEvent);

    return () => {
      window.removeEventListener("imageLoad", imageLoadEvent);
      window.removeEventListener("imageLoaded", imageLoadedEvent);
    }

  }, [imageFinishToLoad]);

  return (
    <>
      <div id='ms-loadingPage' className='ms-loadingPage' style={{ display: (loading ? 'block' : 'none') }}>
        <div className="ms-loadingPage-logoWrapper">
          <Logo />
        </div>
        <div className="ms-loadingPage-lottieWrapper">
          <Player loop src={animationData} autoplay={loading} />
        </div>
        <div className="ms-loadingPage-messageWrapper">
          <label>chargement en cours...</label>
        </div>
      </div>
    </>
  );
})

export default LoadingPage;
