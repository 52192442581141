import './Divider.scss'

const Divider = (props: any) => {

  return (   
      <div className={`bd-divider-v-${props.height} bd-divider-h-${props.width}` }/> 
  );
};

Divider.defaultProps = {
    width: '0',
    height: '0'
}

export default Divider;
