import ApiWrapper from './ApiWrapper';
import { TMainConfig, TCategory, TScore } from '../types'

const apiName = 'ApiGatewayRestApi';
const memoryCache: any = {};


export default class BaseDS {

  static completeGame = async (scoreDetails: TScore): Promise<TScore[]> => {
    return ApiWrapper.post(apiName, `/score`, { body: scoreDetails });
  }

  static positionForScore = async (scoreDetails: TScore): Promise<TScore[]> => {
    return ApiWrapper.get(apiName, `/score/position?level=${scoreDetails.level}&category=${scoreDetails.category}&score=${scoreDetails.score}`);
  }

  static getScores = async (level: number, category: number): Promise<any> => {
    return ApiWrapper.get(apiName, `/score?level=${level}&category=${category}`);
  }

  static getMainConfig = async (callback: Function) => {
    if(memoryCache['mainConfig']) return callback(memoryCache['mainConfig']);
    else return ApiWrapper.get(apiName, '/main/first').then((data: TMainConfig) => {
      memoryCache['mainConfig'] = data;
      callback(data);
    });
  };

  static getFullCategoryById = async (categoryId: string): Promise<TCategory> => {
    return ApiWrapper.get(apiName, `/category/getFullObjectById/${categoryId}`);
  };

}

