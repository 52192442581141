
import { Auth } from 'aws-amplify';
import { TUserInfo } from 'types';

export default class UserDS {

  static getCurrentUserProfil = (callback: Function) => {
    Auth.currentUserInfo().then((user) => {
      const userResult: TUserInfo = {
        userId: user?.attributes.sub,
        profilKey: user?.attributes.name
      };
      callback(userResult);
    })
  }
}