import "./QuizBackground.scss";
import { Context } from "quiz-state-management/store";
import { useContext, useEffect, useRef } from "react";
import { ReactComponent as CloudsBig}  from "assets/quiz/themes/theme-clouds-big.svg";
import { ReactComponent as CloudsSmall} from "assets/quiz/themes/theme-clouds-small.svg";
import useMeasure from 'react-use-measure'
import { Themes } from "quiz-state-management/constants";
import { gsap, Linear } from "gsap";

interface Props {
  theme: string;
}

const QuizBackground = (props: Props) => {
  const [state] = useContext(Context);

  const BackScroll = Themes[state.theme].backScroll;

  const cloudsSmallRef = useRef(null);
  const cloudsBigRef = useRef(null);
  const backScrollRef = useRef(null);

  const [ mainRef, mainBounds]  = useMeasure();
  const animation: any = useRef();

  useEffect(() => {
    if (animation.current) {
      if (state.paused) {
        animation.current.pause();
      } else {
        animation.current.play();
      }
    }
  }, [state.paused]);

  useEffect(() => {
    if (mainBounds.width > 0 && state.playing) {

      animation.current = gsap
        .timeline()
        .to(cloudsBigRef.current, {
          x: -mainBounds.width,
          duration: 60,
          ease: Linear.easeNone,
          repeat: -1,
        })
        .to(
          cloudsSmallRef.current,
          {
            x: -mainBounds.width,
            duration: 150,
            ease: Linear.easeNone,
            repeat: -1,
          },
          0
        )
        .to(
          cloudsBigRef.current,
          {
            x: -mainBounds.width,
            duration: 100,
            ease: Linear.easeNone,
            repeat: -1,
          },
          0
        )
        .to(
          backScrollRef.current,
          {
            x: -mainBounds.width,
            duration: 300,
            ease: Linear.easeNone,
            repeat: -1,
          },
          0
        );
    }
    return () => {
      if (animation.current) {
        animation.current.kill();
      }
    };
  }, [mainBounds.width, state.playing]);

  return (
    <div
      ref={mainRef}
      className={`bd-quiz-themes__main bd-quiz-themes__main--${props.theme}`}
    >
      {(state.playing && state.mode === 0) && (
        <>  
          <BackScroll ref={backScrollRef} className="bd-quiz-themes__back-scroll"/>
         
          <CloudsSmall           
            ref={cloudsSmallRef}
            className="bd-quiz-themes__clouds-small"
          />
          {props.theme !=='night' &&
          <CloudsBig
            ref={cloudsBigRef}
            className="bd-quiz-themes__clouds-big"
          />
      }
        </>
      )}
    </div>
  );
};

export default QuizBackground;
