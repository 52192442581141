import { initialScore } from 'quiz-state-management/constants';

const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case "RESET_QUIZ":
      return {
        ...state,
        data: null,       
        mp3Loaded: false,
        score: initialScore,
        correctScore: 0,
        correct_incorrect: "",
        won_lost: "",
        playing: false,
        paused: false,
        scoreSequence: [],
        questionId: 0,
      };
    case "SET_QUIZ_DATA":
      return {
        ...state,
        data: action.payload,
      };
    case "SET_DATA_LOADED":
      return {
        ...state,
        dataLoaded: true,
      };
      case "SET_MP3_LOADED":
      return {
        ...state,
        mp3Loaded: true,
      };
    case "SET_SECTION":
      return {
        ...state,
        section: action.payload,
      };
    case "SET_DIFFICULTY":
      return {
        ...state,
        difficulty: action.payload,
      };
    case "SET_VOIX_QUESTION":
      return {
        ...state,
        voixQuestion: action.payload,
      };
    case "SET_BRUITAGES":
      return {
        ...state,
        bruitages: action.payload,
      };
    case "SET_MODE":
      return {
        ...state,
        mode: action.payload,
      };
    case "SET_THEME":
      return {
        ...state,
        theme: action.payload,
      };
    case "SET_PAUSE":
      return {
        ...state,
        paused: action.payload,
      };
    case "SET_NEXT_QUESTION":
      return {
        ...state,
        questionId: state.questionId + 1,
        correct_incorrect: "",
      };
    case "SET_PREVIOUS_QUESTION":
      return {
        ...state,
        questionId: state.questionId - 1,
        correct_incorrect: "",
      };
    case "ADD_TO_SCORE_SEQUENCE":
      return {
        ...state,
        scoreSequence: [...state.scoreSequence, action.payload],
      };
    case "WON_LOST":
      return {
        ...state,
        won_lost: action.payload,
      };
    case "SET_PLAYING":
      return {
        ...state,
        playing: action.payload,
      };
    case "SET_CORRECT_SCORE":
      return {
        ...state,
        correctScore: state.correctScore + 1,
      };
    case "SET_CORRECT_INCORRECT":
      return {
        ...state,
        correct_incorrect: action.payload,
      };
    case "SET_SCORE":
      return {
        ...state,
        score: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
